import React, { FunctionComponent } from 'react';
import AssetCard from 'common/components/AssetCard';
import AssetCardSkeleton from 'common/components/AssetCard/AssetCardSkeleton';
import { getAssets, getAssetsAreLoading } from '../store/AssetSlice';
import { useSelector } from 'react-redux';

const AssetList: FunctionComponent = () => {
  const assets = useSelector(getAssets);
  const isLoading = useSelector(getAssetsAreLoading);

  const cards = [];
  if (isLoading) {
    for (let i = 0; i < 3; i++) {
      cards.push(<AssetCardSkeleton key={i} />);
    }
  } else {
    for (let i = 0; i < assets.length; i++) {
      cards.push(
        <AssetCard
          name={assets[i].name}
          url={assets[i].url}
          description={assets[i].description}
          viewCount={assets[i].viewCount}
          updatedAt={assets[i].lastUpdated}
          sourceDomain={assets[i].sourceDomain}
          displayType={assets[i].type}
          provenance={assets[i].provenance}
          categories={[assets[i].category]}
          scope={'scopeDummy'}
          key={i}
          tags={assets[i].tags}
          sourceLink={assets[i].sourceLink}
        />
      );
    }
  }
  return <>{cards}</>;
};

export default AssetList;
