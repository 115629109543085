import React, { FunctionComponent, useEffect } from 'react';
import { fetchCatalogConfig, fetchFederationFilterCname, getCatalogConfig } from './store/CatalogConfigSlice';
import { useSelector } from 'react-redux';
import { ForgeBanner, ForgeButton, ForgeDrawer, ForgeIcon, ForgeScaffold } from '@tylertech/forge-react';
import I18n from 'common/i18n';
import FeatureFlags from 'common/feature_flags';
import { useAppDispatch } from './store/hooks';
import { fetchAssetsByParameters, getAssetsAreLoading, getResultsTotalSize } from './store/AssetSlice';
import AssetSearchAutocomplete from '../assetSearchAutocomplete';
import AssetList from './components/AssetList';
import FiltersWrapper from './components/FiltersWrapper';
import ResultsPaginator from './components/ResultsPaginator';
import SortResults from './components/SortResults';
import NoResultsPage from './components/NoResultsPage';
import { getParamValueInUrl } from 'accessibleBrowseFilters/helpers';
import {
  updateQInUrl,
  updatePageNumberInUrl,
  updatePageSizeInUrl,
  updateSortByInUrl,
  updateTagInUrl,
  getFilterQueries,
  getCustomFacets
} from './store/UrlSlice';
import { getFeaturedContentKey, translateSortByToOrder } from './helpers';
import { DEFAULT_PAGE_SIZE, DEFAULT_PAGE_SIZE_OPTIONS } from './types';
import FeaturedContentWrapper from 'browse3/components/FeaturedContentWrapper';
import FilterChips from './components/FilterChips';
import './Browse3App.scss';
import FeaturedContentBanner from './components/FeaturedContentBanner';
import { currentUserHasRight } from 'common/current_user';
import DomainRights from 'common/types/domainRights';
import {
  fetchCatalogLandingPageConfig,
  getCatalogLandingPageConfig
} from './store/CatalogLandingPageConfigSlice';

const Browse3App: FunctionComponent = () => {
  const scope = 'controls.browse.browse3';
  const dispatch = useAppDispatch();
  const catalogConfig = useSelector(getCatalogConfig);
  const total = useSelector(getResultsTotalSize);
  const assetsAreLoading = useSelector(getAssetsAreLoading);
  const catalogLandingPageConfig = useSelector(getCatalogLandingPageConfig);
  const urlFilters = useSelector(getFilterQueries);
  const customFacets = useSelector(getCustomFacets);

  useEffect(() => {
    dispatch(fetchCatalogConfig());
    dispatch(fetchCatalogLandingPageConfig());
  }, []);

  useEffect(() => {
    if (catalogConfig.configLoaded) {
      // if the config is loaded, we can fetch assets

      // Parse the URL to find the valid query parameters
      const queryInUrl = getParamValueInUrl('q') ?? '';
      const pageNumber = parsePageNumberInUrl();
      const pageSize = parsePageSizeInUrl();
      const sortByInUrl = parseSortByInUrl();
      const tagInUrl = getParamValueInUrl('tags');

      // Initialize state in UrlSlice
      dispatch(updateQInUrl(queryInUrl));
      dispatch(updateSortByInUrl(sortByInUrl));
      dispatch(updatePageNumberInUrl(pageNumber));
      dispatch(updateTagInUrl(tagInUrl));
      dispatch(updatePageSizeInUrl(pageSize));

      // Fetch the assets based on the query parameters
      dispatch(fetchAssetsByParameters());
    } else if (catalogConfig.federation_filter.length > 0) {
      // otherwise, if we're not finished loading config and we have a federation filter domain, we need to fetch the cname
      dispatch(fetchFederationFilterCname(catalogConfig.federation_filter));
    }
  }, [catalogConfig]);

  const parsePageNumberInUrl = (): number => {
    const pageInUrl = getParamValueInUrl('page');
    return pageInUrl ? parseInt(pageInUrl) : 1;
  };

  const parsePageSizeInUrl = (): number => {
    // If the pageSize value is not one of the pageSize options, change it to the default.
    const pageSizeInUrl = getParamValueInUrl('pageSize');
    const pageSize = pageSizeInUrl ? parseInt(pageSizeInUrl) : DEFAULT_PAGE_SIZE;
    return DEFAULT_PAGE_SIZE_OPTIONS.includes(pageSize) ? pageSize : DEFAULT_PAGE_SIZE;
  };

  const parseSortByInUrl = (): string => {
    const sortByInUrl = getParamValueInUrl('sortBy') ?? '';
    return translateSortByToOrder(sortByInUrl) ?? 'relevance';
  };

  const getTitle = (): string => {
    if (!urlFilters) return I18n.t('catalog', { scope });
    const key = getFeaturedContentKey(urlFilters, customFacets);
    const clpConfig = catalogLandingPageConfig.results.find((config) => config.name === key);
    if (clpConfig && clpConfig.headline) return clpConfig.headline;
    return I18n.t('catalog', { scope });
  };

  const getDescriptionSection = () => {
    if (!urlFilters) return null;
    const key = getFeaturedContentKey(urlFilters, customFacets);
    const clpConfig = catalogLandingPageConfig.results.find((config) => config.name === key);
    if (clpConfig && clpConfig.description) return <div className="catalog-description">{clpConfig.description}</div>;
    return null;
  };

  return (
    <ForgeScaffold>
      <ForgeDrawer slot={'left'} open={true} direction={'left'}>
        <FiltersWrapper />
      </ForgeDrawer>
      <div slot={'header'} className="featured-content-banner">
        <div className="browse3-preview-banner">
          {FeatureFlags.value('browse_preview_banner') && (
            <ForgeBanner>
              <div>
                {I18n.t('browse3_tech_preview_banner', { scope })}
                <ForgeButton type="outlined">
                  <button
                    type="button"
                    onClick={() => {
                      window.location.href = window.location.origin + '/browse';
                    }}
                  >
                    <ForgeIcon name="swap_horiz"></ForgeIcon>
                    <span>{I18n.t('browse3_tech_preview_button', { scope })}</span>
                  </button>
                </ForgeButton>
              </div>
            </ForgeBanner>
          )}
        </div>
        {currentUserHasRight(DomainRights.feature_items) && <FeaturedContentBanner />}
      </div>
      <div slot="body-header" className="browse3-header">
        <div className="catalog-header">
          <div className="forge-typography--headline4">{getTitle()}</div>
          <AssetSearchAutocomplete />
        </div>
        {getDescriptionSection()}
        <FeaturedContentWrapper />
        <div className="catalog-results">
          <div className="results-left-section">
            <div className="forge-typography--body1">
              <strong>{total}</strong>{' '}
              {total === 1 ? I18n.t('result', { scope }) : I18n.t('results', { scope })}
            </div>
            <FilterChips />
          </div>
          <div className="sort-results">
            <SortResults />
          </div>
        </div>
      </div>
      <div slot="body" className="browse3-body">
        <div className="body-with-cards-and-paginator">
          {total == 0 && !assetsAreLoading ? <NoResultsPage /> : <AssetList />}
          <ResultsPaginator />
        </div>
      </div>
    </ForgeScaffold>
  );
};

export default Browse3App;
