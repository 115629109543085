import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from './store';
import { fetchJsonWithDefaultHeaders } from 'common/http';
import { FeaturedContentEntry } from 'browse3/types';

export interface FeaturedContentState {
  results: FeaturedContentEntry[];
}

const initialState: FeaturedContentState = {
  results: []
};

export const fetchFeaturedContent = createAsyncThunk('featuredContent/fetchFeaturedContent', async (featuredContentKey: string, thunkAPI) => {
  try {
    const response = await fetchJsonWithDefaultHeaders(`/api/featured_content/${featuredContentKey}?parentType=catalog_query`, {});
    return response;
  } catch (error) {
    return null;
  }
});

export const FeaturedContentSlice = createSlice({
  name: 'featuredContent',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchFeaturedContent.fulfilled, (state, action) => {
        state.results = [];
        if (!action.payload) return;
        action.payload.map((result: any) => {
          const contentType = result.contentType;
          state.results.push({
            featuredLensUid: result.featuredLensUid,
            contentType: contentType,
            description: result.description,
            position: result.position,
            title: result.title,
            url: result.url,
            updatedAt: result.featuredView?.viewLastModified || undefined,
            displayType: (contentType === 'internal') ? (result.featuredView?.displayType || 'dataset') : 'href',
            viewCount: result.featuredView?.viewCount || 0
          });
        });
      });
  }
});

export const getFeaturedContent = (state: RootState) => {
  return state.featuredContent.results;
};


