import React, { FunctionComponent } from 'react';
import { ForgeCard, ForgeLabelValue } from '@tylertech/forge-react';
import { formatDateWithLocale } from 'common/dates';
import I18n from 'common/i18n';

const t = (k: string, options: { [key: string]: any } = {}) =>
  I18n.t(k, { scope: 'shared.components.asset_card', ...options });

export interface FeaturedContentCardProps {
  name: string;
  url: string;
  updatedAt?: number;
  viewCount?: number;
  displayType: string;
  contentType: string;
  description: string;
}

const FeaturedContentCard: FunctionComponent<FeaturedContentCardProps> = ({
  name,
  url,
  updatedAt,
  viewCount,
  displayType,
  contentType,
  description
}) => {
  const getIllustrationLinkForDisplayType = (type: string) => {
    const basePath = 'https://cdn.forge.tylertech.com/v1/images/spot/';
    switch (type) {
      case 'schedule':
      case 'calendar':
        return basePath + 'calendar-2-spot.svg';

      case 'federated_href':
      case 'href':
        return 'images/link_illustration.svg';

      case 'story':
        return basePath + 'designer-spot.svg';

      case 'data_lens_map':
      case 'geomap':
      case 'intensitymap':
      case 'visualization_canvas_map':
      case 'map':
        return basePath + 'map-2-spot.svg';

      case 'annotatedtimeline':
      case 'areachart':
      case 'barchart':
      case 'chart':
      case 'columnchart':
      case 'data_lens_chart':
      case 'visualization_canvas_chart':
      case 'imagesparkline':
      case 'linechart':
      case 'piechart':
        return basePath + 'data-points-spot.svg';

      case 'measure':
        return basePath + 'chart-spot.svg';

      case 'form':
        return basePath + 'checklist-spot.svg';

      case 'attachment':
      case 'blob':
      case 'file':
        return basePath + 'paperclip-spot.svg';

      case 'blist':
      case 'dataset':
      case 'federated':
      case 'visualization_canvas_table':
      case 'table':
      case 'data_asset':
      case 'system_dataset':
        return basePath + 'data-table-spot.svg';

      default:
        return basePath + 'data-table-spot.svg'; // going to have a default otherwise the icons will break
    }
  };

  const epochToDate = (epoch: number | undefined) => {
    if (!epoch) return undefined;
    const date = new Date(0);
    date.setSeconds(epoch);
    return date;
  };

  const getAssetDataSection = () => {
    if (contentType === 'external') {
      return <div className="asset-data">
        <span className="asset-description">{description}</span>
      </div>;
    } else {
      return <div className="asset-data">
          <div className="last-updated">
            <ForgeLabelValue>
              <span slot="label">{t('last_updated')}</span>
              <span data-testid="featured-content-updated-at" slot="value">{formatDateWithLocale(epochToDate(updatedAt))}</span>
            </ForgeLabelValue>
          </div>
          <div className="view-count">
            <ForgeLabelValue>
              <span slot="label">{t('views')}</span>
              <span data-testid="featured-content-view-count" slot="value">{viewCount || 0}</span>
            </ForgeLabelValue>
          </div>
        </div>;
    }
  };

  const handleCardClick = (event: Event) => {
    event.preventDefault();
    window.open(url);
  };

  return (
      <ForgeCard onClick={handleCardClick} className="featured-content-card">
        <div className="header">
          <img className="asset-card-illustration" src={getIllustrationLinkForDisplayType(displayType)} alt="Featured asset icon" />
          <a href={url} className="asset-title forge-typography--subtitle1-secondary">{name}</a>
        </div>
        {getAssetDataSection()}
      </ForgeCard>
  );
};

export default FeaturedContentCard;
