import React, { FunctionComponent, ReactElement } from 'react';
import { createNewTextSearchInUrl, getActiveFilters, getAllFilters, removeActiveFilter, removeAllFiltersAndTagsFromUrl } from '../store/UrlSlice';
import { useSelector } from 'react-redux';
import { ForgeButton, ForgeChip } from '@tylertech/forge-react';
import { useAppDispatch } from 'browse3/store/hooks';
import { fetchAssetsByParameters } from 'browse3/store/AssetSlice';
import I18n from 'common/i18n';
import { Filter } from 'accessibleBrowseFilters/types';
import { FilterQuery } from 'browse3/types';
import { translateDisplayTypeFromUrlToCeteraFormat, translateParamToCeteraQueryParam } from 'browse3/helpers';

const FilterChips: FunctionComponent = () => {
  const scope = 'controls.browse.browse3.filter';
  const chips: ReactElement[] = [];

  const dispatch = useAppDispatch();
  const activeFilters = useSelector(getActiveFilters);
  const allFilters = useSelector(getAllFilters);

  const onDeleteClick = (e: CustomEvent) => {
    dispatch(removeActiveFilter(e.detail.value));
    dispatch(fetchAssetsByParameters());
  };

  const onClearAllFilters = () => {
    dispatch(removeAllFiltersAndTagsFromUrl());
    dispatch(fetchAssetsByParameters());
  };

  const translateFilterValueToFilterOptionText = (filterQuery: FilterQuery): string => {
    let optionText = filterQuery.paramValue;
    const filter: Filter | undefined = allFilters?.find((f) => translateParamToCeteraQueryParam(f.param) === translateParamToCeteraQueryParam(filterQuery.queryParam));
    if (filter?.param == 'federation_filter') {
      optionText = filter?.title + ': ' + optionText;
    } else if (filterQuery.queryParam == 'tags') {
      optionText = 'Tags: ' + optionText;
    } else if (filterQuery.queryParam == 'q') {
      optionText = '"' + optionText + '"';
    } else {
      const filterOption = filter?.options.find((o) => translateDisplayTypeFromUrlToCeteraFormat(o.value) === filterQuery.paramValue);
      if (filterOption)
        optionText = filter?.title + ': ' + filterOption.text;
    }
    return optionText;
  };

  if (activeFilters && activeFilters.length > 0) {
    activeFilters.map((filter, index) => {
      chips.push(
        <ForgeChip key={index} value={filter} dense={true} type='input' on-forge-chip-delete={onDeleteClick}>
          {translateFilterValueToFilterOptionText(filter)}
        </ForgeChip>
      );
    });
    chips.push(
      <ForgeButton key='filter-chips-clear-all-button'>
          <button data-testid="filter-chips-clear-all-button" onClick={onClearAllFilters}>
          {I18n.t('clear_all', { scope })}
          </button>
      </ForgeButton>
    );
  }

  return <>{chips}</>;
};

export default FilterChips;
