import { PayloadAction, createSlice, current } from '@reduxjs/toolkit';
import { removeQueryStringFromUrlNoReload, updateQueryStringNoReload } from '../helpers';
import { RootState } from './store';
import {AssetSearchOptions, CustomFacet, DEFAULT_PAGE_SIZE, FilterQuery, FilterUpdateInfo, SortType} from '../types';
import { getAudienceForUser, translateCeteraQueryParamToUrlParam } from '../helpers';
import { Filter } from 'accessibleBrowseFilters/types';

export interface UrlState {
  searchOptions: AssetSearchOptions;
  allActiveFilters: FilterQuery[]; // stores filters in the order they were selected to be displayed by filter chips
  allFilters: Filter[];
  customFacets: CustomFacet[];
}

const initialState: UrlState = {
  searchOptions: {
    approvalStatus: 'approved',
    audience: getAudienceForUser(),
    explicitlyHidden: false,
    limit: DEFAULT_PAGE_SIZE,
    order: 'relevance',
    pageNumber: 1, // Because cetera query expects 1 based indexing
    q: '',
    published: true,
    tags: '',
    filters: []
  },
  allActiveFilters: [],
  allFilters: [],
  customFacets: []
};

const addFilterToActiveFilters = (filters: FilterQuery[], ceteraParam: string, paramValue: string) => {
  const filter: FilterQuery = { queryParam: ceteraParam, paramValue };
  const activeFilter = filters.find((f: FilterQuery) => f.queryParam === filter.queryParam);
  if (activeFilter && activeFilter.paramValue !== filter.paramValue) {
    // if the value of the filter changed then remove it and add a new one
    filters.splice(filters.indexOf(activeFilter), 1);
    filters.push(filter);
  } else if (!activeFilter && filter.paramValue) {
    // if the filter is not in the active filters then add it
    filters.push(filter);
  }
};

const removeFilterFromActiveFilters = (state: UrlState, queryParam: string) => {
  state.allActiveFilters = state.allActiveFilters.filter((f) => f.queryParam !== queryParam);
};

const reconcileFiltersWithActiveFilters = (state: UrlState) => {
  const newActiveFilters: FilterQuery[] = [];
  current(state.allActiveFilters).forEach((filter: FilterQuery) => {
    // remove filters from active filters that are no longer in the filter bar (leave q and tags in active filters)
    const filterInFilterBar = state.searchOptions.filters?.find((f) => f.queryParam === filter.queryParam);
    if (filterInFilterBar || filter.queryParam === 'q' || filter.queryParam === 'tags') {
      newActiveFilters.push(filter);
    }
  });
  // add new selections from the filter bar to the active filters
  state.searchOptions.filters?.forEach((filter) => {
    addFilterToActiveFilters(newActiveFilters, filter.queryParam, filter.paramValue);
  });
  state.allActiveFilters = newActiveFilters;
};

const removeFilterByQueryParam = (state: UrlState, queryParam: string) => {
  resetPageNumber(state);
  removeQueryStringFromUrlNoReload(translateCeteraQueryParamToUrlParam(queryParam));
  state.searchOptions.filters = state.searchOptions.filters?.filter((f) => f.queryParam !== queryParam);
};

const resetPageNumber = (state: UrlState) => {
  state.searchOptions.pageNumber = 1;
  removeQueryStringFromUrlNoReload('page');
};

export const UrlSlice = createSlice({
  name: 'url',
  initialState,
  reducers: {
    createNewTextSearchInUrl: (state, action: PayloadAction<string>) => {
      const { payload } = action;
      state.searchOptions.q = payload;
      // Reset query parameters to initial values
      updateQueryStringNoReload('q', payload);
      resetPageNumber(state);
      addFilterToActiveFilters(state.allActiveFilters, 'q', payload);
    },
    updateQInUrl: (state, action: PayloadAction<string>) => {
      const { payload } = action;
      state.searchOptions.q = payload;
      updateQueryStringNoReload('q', payload);
      resetPageNumber(state);
      addFilterToActiveFilters(state.allActiveFilters, 'q', payload);
    },
    updateFilterQueriesInUrl: (state, action: PayloadAction<FilterUpdateInfo>) => {
      const { payload } = action;
      resetPageNumber(state);
      // Sometimes the key/value pair in the URL will have a different value than the value passed to catalog
      if (payload.urlParts.filterValueForUrl) {
        updateQueryStringNoReload(payload.urlParts.filterParam, payload.urlParts.filterValueForUrl);
      } else {
        updateQueryStringNoReload(payload.urlParts.filterParam, payload.urlParts.filterValue);
      }
      state.searchOptions.filters = payload.filters;
      reconcileFiltersWithActiveFilters(state);
    },
    updateTagInUrl: (state, action: PayloadAction<string | null>) => {
      const { payload } = action;
      if (payload) {
        state.searchOptions.tags = payload;
        resetPageNumber(state);
        updateQueryStringNoReload('tags', payload);
        addFilterToActiveFilters(state.allActiveFilters, 'tags', payload);
      }
    },
    removeTagFromUrl: (state) => {
      resetPageNumber(state);
      state.searchOptions.tags = '';
      removeQueryStringFromUrlNoReload('tags');
      removeFilterFromActiveFilters(state, 'tags');
    },
    removeAllFiltersAndTagsFromUrl: (state) => {
      state.searchOptions.q = '';
      state.searchOptions.pageNumber = 1;
      state.searchOptions.tags = '';
      state.allActiveFilters = [];
      removeQueryStringFromUrlNoReload('q');
      removeQueryStringFromUrlNoReload('page');
      removeQueryStringFromUrlNoReload('tags');
      if (state.searchOptions.filters) {
        state.searchOptions.filters.forEach((filter: FilterQuery) => {
          const queryUrlParam = translateCeteraQueryParamToUrlParam(filter.queryParam);
          removeQueryStringFromUrlNoReload(queryUrlParam);
        });
        state.searchOptions.filters = [];
      }
    },
    updatePageNumberInUrl: (state, action) => {
      const { payload } = action;
      state.searchOptions.pageNumber = payload;
      updateQueryStringNoReload('page', payload);
    },
    updatePageSizeInUrl: (state, action) => {
      const { payload } = action;
      state.searchOptions.limit = payload;
      updateQueryStringNoReload('pageSize', payload);
    },
    updateSortByInUrl: (state, action) => {
      const { payload } = action;
      state.searchOptions.order = payload;
      updateQueryStringNoReload('sortBy', SortType[payload]);
    },
    updateSortByAndResetPageInUrl: (state, action) => {
      const { payload } = action;
      state.searchOptions.order = payload;
      state.searchOptions.pageNumber = 1;
      updateQueryStringNoReload('sortBy', SortType[payload]);
      removeQueryStringFromUrlNoReload('page');
    },
    removeActiveFilter: (state, action: PayloadAction<FilterQuery>) => {
      const { payload } = action;
      removeFilterFromActiveFilters(state, payload.queryParam);
      if (payload.queryParam === 'q') {
        UrlSlice.caseReducers.createNewTextSearchInUrl(state, {
          type: 'createNewTextSearchInUrl',
          payload: ''
        });
      } else if (payload.queryParam === 'tags') {
        UrlSlice.caseReducers.removeTagFromUrl(state);
      } else {
        removeFilterByQueryParam(state, payload.queryParam);
      }
    },
    setAllFilters: (state, action: PayloadAction<Filter[]>) => {
      const { payload } = action;
      state.allFilters = payload;
    },
    setCustomFacets: (state, action: PayloadAction<CustomFacet[]>) => {
      const { payload } = action;
      state.customFacets = payload;
    },
    initializeUrlFilters(state: UrlState, action: PayloadAction<FilterQuery[]>) {
      const { payload } = action;
      const newActiveFilters: FilterQuery[] = [];
      state.allActiveFilters.forEach((filter: FilterQuery) => {
        newActiveFilters.push(filter);
      });
      payload.forEach((filter) => {
        if (!newActiveFilters.find((f) => f.queryParam === filter.queryParam)) {
          newActiveFilters.push(filter);
        }
      });
      state.searchOptions.filters = payload;
      state.allActiveFilters = newActiveFilters;
    }
  }
});

// Selectors
export const getTextQuery = (state: RootState) => {
  return state.url.searchOptions.q;
};
export const getPageNumber = (state: RootState) => {
  return state.url.searchOptions.pageNumber;
};
export const getLimit = (state: RootState) => {
  return state.url.searchOptions.limit;
};
export const getOrder = (state: RootState) => {
  return state.url.searchOptions.order;
};

export const getFilterQueries = (state: RootState) => {
  return state.url.searchOptions.filters;
};

export const getActiveFilters = (state: RootState) => {
  return state.url.allActiveFilters;
};

export const getAllFilters = (state: RootState) => {
  return state.url.allFilters;
};

export const getCustomFacets = (state: RootState) => {
  return state.url.customFacets;
};

export const getSelectedTag = (state: RootState) => {
  return state.url.searchOptions.tags;
};

export default UrlSlice.reducer;
export const {
  updateFilterQueriesInUrl,
  createNewTextSearchInUrl,
  updateQInUrl,
  updatePageNumberInUrl,
  updateTagInUrl,
  removeTagFromUrl,
  removeAllFiltersAndTagsFromUrl,
  updatePageSizeInUrl,
  updateSortByInUrl,
  updateSortByAndResetPageInUrl,
  removeActiveFilter,
  setAllFilters,
  initializeUrlFilters,
  setCustomFacets
} = UrlSlice.actions;
