import { getCurrentDomain } from 'common/currentDomain';
import { currentUserHasRight } from 'common/current_user';
import FeatureFlags from 'common/feature_flags';
import {
  AssetSearchOptions,
  CatalogConfig,
  Category,
  CustomFacet,
  Federations,
  FilterQuery,
  ForgeItemValues
} from './types';
import DomainRights from 'common/types/domainRights';
import I18n from 'common/i18n';
import { Filter, FilterOption, FilterOptionChild } from 'accessibleBrowseFilters/types';
import _ from 'lodash';

export const alphabetizeFilters = (filters: Filter[]): Filter[] => {
  // loop through filters, sorting each of their options alphabetically
  filters.forEach((f) => {
    f.options.sort(function (filter1, filter2) {
      // I don't know if these will always be the same case, so make everything lowercase to compare
      const option1: string = filter1.text.toLowerCase();
      const option2: string = filter2.text.toLowerCase();
      if (option1 < option2) {
        return -1;
      } else if (option1 > option2) {
        return 1;
      } else {
        return 0;
      }
    });
  });

  return filters;
};

export const catalogConfigToSortBy = (catalogConfig: CatalogConfig): AssetSearchOptions => {
  const queryParams: AssetSearchOptions = {};
  if (catalogConfig.properties && catalogConfig.properties.length > 0) {
    catalogConfig.properties.map((property) => {
      if (property.name === 'sortBy') {
        queryParams.order = translateSortByToOrder(property.value as string);
      }
    });
  }
  return queryParams;
};

export const catalogConfigToFederationFilter = (catalogConfig: CatalogConfig): string => {
  let federation_filter = '';
  if (catalogConfig.properties && catalogConfig.properties.length > 0) {
    catalogConfig.properties.map((property) => {
      if (property.name === 'federation_filter') {
        federation_filter = property.value.toString();
      }
    });
  }
  return federation_filter;
};

export const generateProvenanceFilter = () => {
  const scope = 'controls.browse.browse3.filter';

  const officialFilterOption: FilterOption = {
    text: I18n.t('authority.official', { scope }),
    value: 'official'
  };

  const communityFilterOption: FilterOption = {
    text: I18n.t('authority.community', { scope }),
    value: 'community'
  };

  const authorityFilter: Filter = {
    title: I18n.t('authority.title', { scope }),
    param: 'provenance',
    options: [officialFilterOption, communityFilterOption]
  };

  return authorityFilter;
};

export const generateViewTypes = (): Filter => {
  const scope = 'controls.browse.facets.view_types';
  const datasets = 'datasets';
  const charts = 'charts';
  const maps = 'maps';
  const calendars = 'calendars';
  const filters = 'filters';
  const href = 'href';
  const blob = 'blob';
  const forms = 'forms';
  const story = 'story';
  const measures = 'measures';

  const datasetsFilter: FilterOption = {
    text: I18n.t(datasets, { scope }),
    value: datasets
  };

  const chartsFilter: FilterOption = {
    text: I18n.t(charts, { scope }),
    value: charts
  };

  const mapsFilter: FilterOption = {
    text: I18n.t(maps, { scope }),
    value: maps
  };

  const calendarsFilter: FilterOption = {
    text: I18n.t(calendars, { scope }),
    value: calendars
  };

  const filtersFilter: FilterOption = {
    text: I18n.t(filters, { scope }),
    value: filters
  };

  const hrefFilter: FilterOption = {
    text: I18n.t(href, { scope }),
    value: href
  };

  const blobFilter: FilterOption = {
    text: I18n.t(blob, { scope }),
    value: blob
  };

  const formsFilter: FilterOption = {
    text: I18n.t(forms, { scope }),
    value: forms
  };

  const viewTypesFilter: Filter = {
    title: I18n.t('controls.browse.facets.view_types_title'),
    param: 'limitTo',
    options: [
      datasetsFilter,
      chartsFilter,
      mapsFilter,
      calendarsFilter,
      filtersFilter,
      hrefFilter,
      blobFilter,
      formsFilter
    ]
  };

  if (FeatureFlags.value('stories_show_facet_in_catalog')) {
    const storiesFilter: FilterOption = {
      text: I18n.t(story, { scope }),
      value: story
    };

    viewTypesFilter.options.push(storiesFilter);
  }

  if (FeatureFlags.value('open_performance_standalone_measures')) {
    const measuresFilter: FilterOption = {
      text: I18n.t(measures, { scope }),
      value: measures
    };

    viewTypesFilter.options.push(measuresFilter);
  }

  return viewTypesFilter;
};

export const createCustomFilters = (facets: CustomFacet[]): Filter[] => {
  const customFilters: Filter[] = [];
  facets.map((facet) => {
    const filterOptions: FilterOption[] = [];
    facet.options.map((option) => {
      const newOption: FilterOption = {
        text: option,
        value: option
      };
      filterOptions.push(newOption);
    });

    const newFilter: Filter = {
      title: facet.title,
      param: facet.param,
      options: filterOptions
    };

    customFilters.push(newFilter);
  });

  return customFilters;
};

/** This method will change quite a bit with this ticket: EN-67354 */
export const createCategoryFilter = (categories: Category[]): Filter => {
  const DEFAULT_CATEGORIES = [
    'fun',
    'government',
    'personal',
    'education',
    'business',
    'finance',
    'health',
    'other'
  ];
  const scope = 'shared.metadata_template.default_categories';
  const categoryOptions: FilterOption[] = [];

  categories.map((category) => {
    const displayText = _.includes(DEFAULT_CATEGORIES, category.value)
      ? I18n.t(category.value, { scope })
      : _.startCase(category.value);

    const newOption: FilterOption = {
      text: displayText,
      value: displayText
    };

    categoryOptions.push(newOption);

    if (category.children) {
      category.children.map((child) => {
        const cap = _.startCase(child.value);
        const thisChild: FilterOption = {
          text: cap,
          value: cap
        };

        categoryOptions.push(thisChild);
      });
    }
  });

  const categoryFilter: Filter = {
    param: 'category',
    title: I18n.t('controls.browse.facets.categories_title'),
    options: categoryOptions
  };

  return categoryFilter;
};

export const createTags = (tagList: string[]): Filter => {
  const tags: FilterOption[] = [];
  tagList.map((tag) => {
    const newFilterOption: FilterOption = {
      text: tag,
      value: tag
    };

    tags.push(newFilterOption);
  });

  const tagsFilter: Filter = {
    param: 'tags',
    title: I18n.t('controls.browse.facets.tags_title'),
    options: tags
  };
  return tagsFilter;
};

export const createFederationFilter = (federations: Federations): Filter => {
  const filterOptions: FilterOption[] = [];
  federations.options.map((option) => {
    let text: string = option.text;
    if (text === 'This site only') {
      text = I18n.t('controls.browse.facets.this_site_only');
    }

    const filterOption: FilterOption = {
      text: text,
      value: option.value.toString()
    };

    filterOptions.push(filterOption);
  });

  return {
    param: federations.param,
    title: I18n.t('controls.browse.facets.federated_domains_title'),
    options: filterOptions
  };
};

export const getParamValueInUrl = (param: string): string | null => {
  const currentURL: string = window.location.href;
  const searchParams = new URL(currentURL).searchParams;
  return searchParams.get(param);
};

export const isSearchParamInUrl = (param: string, value: string): boolean => {
  const currentURL: string = window.location.href;
  const searchParams = new URL(currentURL).searchParams;
  const results = searchParams.getAll(param); // use getAll with query param name, and then check results for actual param value
  return results.includes(value);
};

export const removeQueryStringFromUrlNoReload = (filterParam: string) => {
  const currentURL: string = window.location.href;
  const url = new URL(currentURL);
  if (url.searchParams.get(filterParam)) {
    url.searchParams.delete(filterParam);
  }
  history.pushState({}, '', url.href);
};

/**
 * Use this method when the query string needs to be updated due to a query param being updated, removed or added.
 * Notably, this does not reload the page when used; it just adds to page history and updates the URL.
 *
 * Function parameters:
 *  newQueryKey: the key of the key/value pair in the query string (ex. given a query string of q=puppies, q is the queryParam)
 *  newQueryValue: the value of the key/value pair in the query string (ex. given a query string of q=puppies, puppies is the paramValue)
 * */
export const updateQueryStringNoReload = (newQueryKey: string, newQueryValue: string) => {
  const currentURL: string = window.location.href;
  const url = new URL(currentURL);
  const currentQueryKey = url.searchParams.get(newQueryKey);

  // if an empty string is passed in, then we want to delete the query param
  // else if there is already an entry for this query param, we want to update its value
  // or we want to add a query param and its value
  if (newQueryValue == '') {
    url.searchParams.delete(newQueryKey);
  } else {
    url.searchParams.set(newQueryKey, newQueryValue);
  }

  history.pushState({}, '', url.href);
};

/**
 * Some of the category options have children. We will display children that are one level deep.
 * To do this, we loop through each option in categories and build up a new filter containing
 * options for each of the options and their first  level children.
 * @param filterFacets All filters on the page
 * @returns
 */
export const restructureCategories = (filterFacets: Filter[]): Filter[] => {
  let categoryFilter: Filter;
  const isCategory = (filter: Filter) => filter.param === 'category';
  const index = filterFacets.findIndex(isCategory);

  if (index != -1) {
    categoryFilter = filterFacets[index];

    const newCategoryFilter: Filter = {
      param: categoryFilter.param,
      title: categoryFilter.title,
      options: []
    };

    categoryFilter.options.map((option) => {
      newCategoryFilter.options.push(option);
      if (option.children && option.children.length > 0) {
        option.children.map((c) => {
          const newOption: FilterOption = {
            text: c.text,
            value: c.value
          };
          newCategoryFilter.options.push(newOption);
        });
      }
    });

    if (categoryFilter.extra_options) {
      categoryFilter.extra_options.map((option) => {
        newCategoryFilter.options.push(option);
        if (option.children && option.children.length > 0) {
          option.children.map((c) => {
            const newOption: FilterOption = {
              text: c.text,
              value: c.value
            };
            newCategoryFilter.options.push(newOption);
          });
        }
      });
    }

    filterFacets[index] = newCategoryFilter;
  }

  return filterFacets;
};

// cetera needs the 'order' param passed in a different format than what we configure as 'sortBy' in the internal catalog config
export const translateSortByToOrder = (sortBy: string): string | undefined => {
  if (sortBy == null) return 'relevance';
  return {
    relevance: 'relevance',
    most_accessed: 'page_views_total',
    alpha: 'name',
    alpha_reversed: 'name DESC',
    newest: 'createdAt',
    oldest: 'createdAt ASC',
    last_modified: 'updatedAt',
    date: 'createdAt',
    name: 'name'
  }[sortBy];
};

export const translateParamToCeteraQueryParam = (param: string): string => {
  switch (param) {
    case 'domain_boosts':
      return 'boostDomains';
    case 'limitTo':
      return 'only';
    case 'datasetView':
      return 'only';
    case 'sortBy':
      return 'order';
    case 'default_sort':
      return 'order';
    case 'category':
      return 'categories';
    case 'federation_filter':
      return 'domains';
    default:
      return param;
  }
};

export const translateCeteraQueryParamToUrlParam = (param: string): string => {
  switch (param) {
    case 'boostDomains':
      return 'domain_boosts';
    case 'only':
      return 'limitTo';
    case 'order':
      return 'sortBy';
    case 'categories':
      return 'category';
    case 'domains':
      return 'federation_filter';
    default:
      return param;
  }
};

export const translateDisplayTypeFromUrlToCeteraFormat = (displayType: string) => {
  switch (displayType) {
    case 'story':
      return 'stories';
    case 'tables':
      return 'datasets';
    case 'blob':
      return 'files';
    case 'href':
      return 'links';
    default:
      return displayType;
  }
};

export const translateDisplayTypeFromCeteraToUrlFormat = (displayType: string) => {
  switch (displayType) {
    case 'stories':
      return 'story';
    case 'files':
      return 'blob';
    case 'links':
      return 'href';
    default:
      return displayType;
  }
};

// If there are already filters applied, ensure the value is updated and that we keep the remaining existing filter parameters.
//  Otherwise, add the filter and its value.
export const updateOrAddFilter = (
  filterQueries: FilterQuery[],
  translatedFilterParam: string,
  translatedFilterValue: string
): FilterQuery[] => {
  const newFilterQueries: FilterQuery[] = [];
  let found = false;

  filterQueries.forEach((filterQuery) => {
    if (filterQuery.queryParam === translatedFilterParam) {
      // push the updated value
      newFilterQueries.push({
        queryParam: translatedFilterParam,
        paramValue: translatedFilterValue
      });
      found = true;
    } else {
      // keep the rest of the filter queries unchanged
      newFilterQueries.push(filterQuery);
    }
  });

  // if the filter param doesn't already exist, add it to the rest of the filter queries
  if (!found) {
    newFilterQueries.push({
      queryParam: translatedFilterParam,
      paramValue: translatedFilterValue
    });
  }

  return newFilterQueries;
};

export const getFeaturedContentKey = (filterParams: FilterQuery[], customFacets: CustomFacet[]) => {
  // if no filter params, use '/browse3' as the key
  const customFacetParams = (customFacets) ? customFacets.map((facet) => facet.param) : [];
  const featuredContentKey = encodeURIComponent(
    filterParams
      .map((param) => {
        if (param.queryParam === 'only')
          return 'limitTo=' + translateDisplayTypeFromCeteraToUrlFormat(param.paramValue);
        else if (param.queryParam === 'categories') return 'category=' + param.paramValue;
        else if (param.queryParam === 'provenance' || param.queryParam === 'federation_filter' || customFacetParams.includes(param.queryParam))
          return param.queryParam + '=' + param.paramValue;
      })
      .sort()
      .join('&')
  ).replace(/%20/g, '+');
  if (featuredContentKey === '')
    return encodeURIComponent('/browse');
  return featuredContentKey;
};

/**
 * Sometimes the query we send to catalog is different than the query displayed in the URL. This is especially
 * true for the federations filter. In the URL, we display "federation_filter=<domain_id>". But when we query catalog,
 * we send "domains=<domain_cname>". So, we have to do some swapping around of values in order to display
 * what we want and pass to catalog the values we need.
 * @param optionText Provided by the DB, intended to be the text displayed next to the radio button for this filter option
 * @param optionValue Provided by the DB, intended to be the query value for the filter.
 * @returns
 */
export const assignForgeListItemValuesForFederationFilter = (
  optionText: string,
  optionValue: string
): ForgeItemValues => {
  const returnValues: ForgeItemValues = {
    forgeListItemText: optionText,
    forgeListItemValue: optionValue,
    urlValue: optionValue
  };

  if (optionText === 'This site only') {
    returnValues.forgeListItemValue = getCurrentDomain();
  } else {
    returnValues.forgeListItemValue = optionText;
  }

  return returnValues;
};

export const getAudienceForUser = (): string[] => {
  if (
    FeatureFlags.value('data_catalog_audience_level') === 'internal' &&
    (currentUserHasRight(DomainRights.can_view_internal_data) ||
      (currentUserHasRight(DomainRights.can_read_metadata) &&
        FeatureFlags.value('enable_can_read_metadata_domain_right')))
  ) {
    return ['public', 'site'];
  } else {
    return ['public'];
  }
};
