import {
  ForgeButton,
  ForgeButtonArea,
  ForgeDivider,
  ForgeExpansionPanel,
  ForgeList,
  ForgeListItem,
  ForgeOpenIcon,
  ForgeRadio
} from '@tylertech/forge-react';
import I18n from 'common/i18n';
import { FilterOption } from 'accessibleBrowseFilters/types';
import React, { FunctionComponent, useEffect } from 'react';
import _ from 'lodash';
import * as helpers from '../helpers';
import { IListItemSelectEventData } from '@tylertech/forge/esm/list';
import { useRef } from 'react';
import { assignForgeListItemValuesForFederationFilter } from '../helpers';

export interface FilterSectionProps {
  options: FilterOption[];
  title: string;
  filterParam: string;
  isMobile: boolean;
  onFilterSelection: (filterParam: string, filterValue: string, urlValue: string) => void;
  onFilterClear: (filterParam: string) => void;
  selectedFilterOptionValue?: string; // optional param to set the selected value rather than looking at the url
}

const FilterSection: FunctionComponent<FilterSectionProps> = (props) => {
  const [isAnySelected, setIsAnySelected] = React.useState<boolean>(
    helpers.getParamValueInUrl(props.filterParam) ? true : false
  );
  const [showSeeMoreButton, setSeeMoreButton] = React.useState<boolean>(false);
  const [optionsToRender, setOptionsToRender] = React.useState<FilterOption[]>([]);
  const expansionPanelRef = useRef(null);
  const [expanded, setExpanded] = React.useState<boolean>(true);

  useEffect(() => {
    if (props.filterParam != 'limitTo' && props.options.length > 5) {
      setSeeMoreButton(true);
      setOptionsToRender(props.options.slice(0, 5));
    } else {
      setSeeMoreButton(false);
      setOptionsToRender(props.options);
    }
  }, []);

  const filterOptionOnClick = (evt: CustomEvent<IListItemSelectEventData>) => {
    // the evt.detail.value is the filter value, i.e. datasets
    if (evt.detail.value === undefined) {
      // we don't expect this to happen, but guarding against edge cases
      return;
    } else {
      setIsAnySelected(true);
      props.onFilterSelection(props.filterParam, evt.detail.value, evt.detail.listItem.target);
    }
  };

  const handleKeyDown = (e: Event) => {
    e.stopPropagation();
  };

  function onToggleExpansionPanel({ detail }: CustomEvent<boolean>): void {
    setExpanded(detail);
  }

  const handleClearClick = (e: Event) => {
    setIsAnySelected(false);
    props.onFilterClear(props.filterParam);
  };

  const handleSeeMoreButton = (e: Event) => {
    setOptionsToRender(props.options);
    setSeeMoreButton(false);
  };

  const renderListOptions = () => {
    const listOptions: JSX.Element[] = [];
    const options: FilterOption[] = optionsToRender;

    for (let i = 0; i < optionsToRender.length; i++) {
      let forgeListItemText: string = options[i].text;
      let forgeListItemValue: string = options[i].value;
      let urlValue: string = options[i].value;

      if (props.filterParam === 'federation_filter') {
        const forgeItemValues = assignForgeListItemValuesForFederationFilter(
          options[i].text,
          options[i].value
        );
        forgeListItemText = forgeItemValues.forgeListItemText;
        forgeListItemValue = forgeItemValues.forgeListItemValue;
        urlValue = forgeItemValues.urlValue;
      }

      let isSelected = helpers.isSearchParamInUrl(props.filterParam, urlValue);
      if (props.selectedFilterOptionValue !== undefined) {
        // if the selectedValue is provided, it will override the url value
        isSelected = props.selectedFilterOptionValue === urlValue;
      }

      listOptions.push(
        <ForgeListItem
          on-forge-list-item-select={filterOptionOnClick}
          value={forgeListItemValue}
          selected={isSelected}
          key={forgeListItemValue}
          target={options[i].value}
          wrap={true}
          forge-drawer-context="false"
        >
          <ForgeRadio slot="leading">
            <input
              type="radio"
              id={props.filterParam + '-radio'}
              name={props.filterParam + '-radio'}
              data-testid={props.filterParam + '-' + forgeListItemValue + '-radio-button'}
              checked={isSelected}
              readOnly={true}
              aria-label={forgeListItemText}
            />
          </ForgeRadio>
          <span id={props.filterParam + '-radio-label'} slot="title">
            {forgeListItemText}
          </span>
        </ForgeListItem>
      );
    }

    return listOptions;
  };

  // Note: "Toggle panel" hard-coded string is never actually displayed to the user, but is required to pass
  // accessibility testing (no empty buttons allowed). See documentation on ButtonArea for more info
  // https://forge.tylerdev.io/main/?path=/docs/components-button-area--default
  return (
    <div className="filter-expansion-panel">
      <ForgeExpansionPanel
        ref={expansionPanelRef}
        open={expanded}
        on-forge-expansion-panel-toggle={onToggleExpansionPanel}
      >
        <ForgeButtonArea slot="header">
          <button
            slot="button"
            type="button"
            data-testid={'toggle-' + props.title + '-expansion-panel'}
            id={'toggle-' + props.title + '-expansion-panel'}
            aria-controls={props.title + ' -expansion-panel-content'}
            aria-expanded="false"
          >
            Toggle panel
          </button>
          <div className="expansion-panel-header">
            <div className="expansion-panel-header-title forge-typography--subtitle1-secondary">
              {props.title}
            </div>
            {isAnySelected && (
              <ForgeButton
                type="dense"
                onClick={handleClearClick}
                data-testid={'clear-' + props.title + '-filter'}
                data-forge-ignore
              >
                <button type="button">
                  <span>{I18n.t('controls.browse.browse3.filter.clear_filters')}</span>
                </button>
              </ForgeButton>
            )}
            <ForgeOpenIcon />
          </div>
        </ForgeButtonArea>
        <div className="expansion-panel-content" id={props.title + '-expansion-panel-content'} role="group">
          <div role="radiogroup" className="empty">
            <ForgeList
              dense
              key={props.title + '-list'}
              onkeydown={handleKeyDown}
              ariaLabel="Choose filter option"
            >
              {renderListOptions()}
            </ForgeList>
          </div>
          {showSeeMoreButton && (
            <ForgeButton type={'flat'} onClick={handleSeeMoreButton} data-testid={'show-more-' + props.title}>
              <button type="button" className="show-more-button">
                <span>{I18n.t('controls.browse.browse3.filter.see_more')}</span>
              </button>
            </ForgeButton>
          )}
        </div>
      </ForgeExpansionPanel>
      <ForgeDivider />
    </div>
  );
};

export default FilterSection;
