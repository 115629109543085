import React, { FunctionComponent, useEffect, useState } from 'react';

import { fetchJsonWithDefaultHeaders } from 'common/http';
import { defaultHeaders } from 'common/http/index';
import I18n from 'common/i18n';
import { Filter } from '../../accessibleBrowseFilters/types';
import FilterSection from './FilterSection';
import AutocompleteField from '../../accessibleBrowseFilters/components/AutocompleteField';
import { ForgeDivider } from '@tylertech/forge-react';
import FeatureFlags from 'common/feature_flags';
import {
  updateFilterQueriesInUrl,
  removeTagFromUrl,
  updatePageNumberInUrl,
  updateTagInUrl,
  setAllFilters,
  getFilterQueries,
  initializeUrlFilters,
  getSelectedTag,
  setCustomFacets
} from '../store/UrlSlice';
import { useAppDispatch } from '../store/hooks';
import { fetchAssetsByParameters } from '../store/AssetSlice';
import { FilterQuery, FilterUpdateInfo } from 'browse3/types';
import { useSelector } from 'react-redux';
import { getCatalogConfig } from 'browse3/store/CatalogConfigSlice';
import {
  translateCeteraQueryParamToUrlParam,
  translateDisplayTypeFromUrlToCeteraFormat,
  translateParamToCeteraQueryParam,
  updateOrAddFilter,
  alphabetizeFilters,
  generateProvenanceFilter,
  generateViewTypes,
  getParamValueInUrl,
  createCustomFilters,
  createFederationFilter,
  createTags
} from 'browse3/helpers';
import { getCurrentDomain } from 'common/currentDomain';
import { FilterConfig } from '../types';
import { createCategoryFilter } from '../helpers';

const FiltersWrapper: FunctionComponent = () => {
  const scope = 'controls.browse.browse3.filter';
  const dispatch = useAppDispatch();
  const catalogConfig = useSelector(getCatalogConfig);
  const urlFilters = useSelector(getFilterQueries);
  const selectedTag = useSelector(getSelectedTag);
  const [filters, setFilters] = React.useState<Filter[]>([]);
  const [tags, setTags] = React.useState<Filter>();
  const fetchFilters = async () => {
    const apiPath = '/api/browse_config';
    const options = {
      credentials: 'same-origin',
      headers: defaultHeaders
    };

    const result = await fetchJsonWithDefaultHeaders(apiPath, options);
    const mappedfilters = mapFacets(result);
    dispatch(setCustomFacets(result.customFacets));
    dispatch(setAllFilters(mappedfilters));
    updateFiltersInUrlSlice(mappedfilters);
  };

  useEffect(() => {
    fetchFilters();
  }, []);

  const mapFacets = (results: FilterConfig): Filter[] => {
    let filterFacets: Filter[] = [];

    if (FeatureFlags.value('show_provenance_facet_in_catalog')) {
      filterFacets.push(generateProvenanceFilter());
    }

    // view types are hard coded and don't rely on the API call
    filterFacets.push(generateViewTypes());

    if (results.categories) {
      filterFacets.push(createCategoryFilter(results.categories));
    }

    if (results.customFacets) {
      filterFacets = filterFacets.concat(createCustomFilters(results.customFacets));
    }

    if (results.federations) {
      filterFacets.push(createFederationFilter(results.federations));
    }

    if (results.tags) {
      setTags(createTags(results.tags.options));
    }

    setFilters(alphabetizeFilters(filterFacets));
    return filterFacets;
  };

  const onFilterSelect = (filterParam: string, filterValue: string, filterItemKey: string) => {
    dispatch(updatePageNumberInUrl(1)); // always reset to first page of results with new filter applied
    let newFilterQueries: FilterQuery[] = [];
    const translatedFilterParam = translateParamToCeteraQueryParam(filterParam);
    const translatedFilterValue = translateDisplayTypeFromUrlToCeteraFormat(filterValue);

    if (urlFilters && urlFilters.length != 0) {
      newFilterQueries = updateOrAddFilter(urlFilters, translatedFilterParam, translatedFilterValue);
    } else if (translatedFilterParam) {
      newFilterQueries.push({
        queryParam: translatedFilterParam,
        paramValue: translatedFilterValue
      });
    }

    let filterValueForUrl = '';
    if (filterParam === 'federation_filter') {
      filterValueForUrl = filterItemKey;
    }

    const filterUpdateInfo: FilterUpdateInfo = {
      urlParts: {
        filterParam: filterParam,
        filterValue: filterValue,
        filterValueForUrl: filterValueForUrl
      },
      filters: newFilterQueries
    };

    dispatch(updateFilterQueriesInUrl(filterUpdateInfo));
    dispatch(fetchAssetsByParameters());
  };

  const onFilterClear = (filterParam: string) => {
    dispatch(updatePageNumberInUrl(1)); // always reset to first page of results with new filter applied

    const translatedFilterParam = translateParamToCeteraQueryParam(filterParam);
    const newFilterQueries: FilterQuery[] = [];
    // keep other existing filters
    urlFilters?.forEach((filterQuery) => {
      if (filterQuery.queryParam !== translatedFilterParam) {
        newFilterQueries.push(filterQuery);
      }
    });

    const filterUpdateInfo: FilterUpdateInfo = {
      urlParts: {
        filterParam: filterParam,
        filterValue: ''
      },
      filters: newFilterQueries
    };

    dispatch(updateFilterQueriesInUrl(filterUpdateInfo));
    dispatch(fetchAssetsByParameters());
  };

  const tagSelectCallback = (tagValue: string) => {
    dispatch(updateTagInUrl(tagValue));
    dispatch(updatePageNumberInUrl(1)); // always reset to first page of results with new filter applied
    dispatch(fetchAssetsByParameters());
  };

  const tagClearButtonCallback = () => {
    dispatch(removeTagFromUrl());
    dispatch(updatePageNumberInUrl(1)); // always reset to first page of results with new filter applied
    dispatch(fetchAssetsByParameters());
  };

  const getSelectedValue = (filterParam: string) => {
    const filter = urlFilters?.find((f) => f.queryParam === filterParam);
    if (filter) {
      return filter.paramValue;
    }
    return undefined;
  };

  const updateFiltersInUrlSlice = (allFilters: Filter[]) => {
    // use the retrieved facets to pull in any filters that are in the URL
    const filterQueries: FilterQuery[] = [];
    allFilters.forEach((filter) => {
      const ceteraParam = translateParamToCeteraQueryParam(filter.param);
      const filterParam = translateCeteraQueryParamToUrlParam(filter.param);
      const filterValue = getParamValueInUrl(filterParam);
      if (filterValue) {
        if (filter.param === 'federation_filter') {
          const filterOption = filter.options.find((option) => option.value === filterValue);
          if (filterOption) {
            let translatedValue = filterOption.text;
            if (translatedValue === 'This site only') {
              translatedValue = getCurrentDomain();
            }
            filterQueries.push({
              queryParam: ceteraParam,
              paramValue: translatedValue
            });
          }
        } else {
          filterQueries.push({
            queryParam: ceteraParam,
            paramValue: filterValue
          });
        }
      }
    });
    if (filterQueries.length > 0) {
      dispatch(initializeUrlFilters(filterQueries));
    }
  };

  return (
    <div>
      <div className="filters-title">
        <span slot="start" id="accessible-browse-filters-title" className="forge-typography--title">
          {I18n.t('filters', { scope })}
        </span>
      </div>
      <div className="filter-sections">
        <ForgeDivider />
        {filters.map((filter) => (
          <FilterSection
            title={filter.title}
            options={filter.options}
            key={filter.title}
            filterParam={filter.param}
            isMobile={false}
            onFilterSelection={onFilterSelect}
            onFilterClear={onFilterClear}
            selectedFilterOptionValue={getSelectedValue(filter.param)}
          />
        ))}
        {tags && (
          <AutocompleteField
            key={tags.param}
            fieldInfo={tags}
            isMobile={false}
            onAutocompleteSelect={tagSelectCallback}
            onClear={tagClearButtonCallback}
            selectedTag={selectedTag}
          />
        )}
      </div>
    </div>
  );
};

export default FiltersWrapper;
